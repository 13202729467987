import { useEffect, useState } from "react";
import api from "../api";
import { HttpError, Setting } from "../api/types";

/**
 * Custom hook to fetch settings from backend.
 * @returns {object} - Contains settings, loading state, error state, and refetch function.
 */
function useFetchSettings() {
  const [settings, setSettings] = useState<Setting[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError | null>(null);

  const fetchSettings = async () => {
    setLoading(true);
    setError(null);

    try {
      const settings = (await api.getSettings()) || [];

      if (api.error) {
        setError(api.error);
      }

      setSettings(settings);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return { settings, loading, error, refetchSettings: fetchSettings };
}

export default useFetchSettings;

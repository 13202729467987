import { addDays, getTime, startOfWeek } from "date-fns";
import { useEffect, useState } from "react";
import { HttpError, SettingName, UserRole } from "../../../api/types";
import useFetchSettings from "../../../hooks/useFetchSettings";
import useAuthStore from "../../../store/auth";
import { Week } from "../types";

const DEFAULT_WEEK_IN_THE_PAST = 0;
const DEFAULT_WEEK_IN_THE_FUTURE = 4;

/**
 * Custom hook to fetch weeks
 * @returns {object} - Contains weeks, loading state, error state, and refetch function.
 */
function useFetchWeeks() {
  const { settings } = useFetchSettings();
  const [user] = useAuthStore(state => [state.user]);

  const [weeks, setWeeks] = useState<Week[]>([]);
  const [currentWeek, setCurrentWeek] = useState<Week>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError | null>(null);

  const fetchWeeks = async () => {
    setLoading(true);
    setError(null);

    try {
      const weeks: Week[] = [];

      const start = startOfWeek(new Date(), { weekStartsOn: 1 });
      const end = addDays(start, 6);

      let weeksInThePast = 0;
      if (user?.role === UserRole.ADMIN) {
        // only admins can see the past weeks
        const weeksInThePastSetting = settings.find(
          s => s.name === SettingName.WEEKS_HISTORY_NUMBER,
        );
        weeksInThePast = Number(weeksInThePastSetting?.value) ?? DEFAULT_WEEK_IN_THE_PAST;
      }

      const weeksInTheFutureSetting = settings.find(s => s.name === SettingName.WEEKS_NUMBER);
      const weeksInTheFuture = Number(weeksInTheFutureSetting?.value) ?? DEFAULT_WEEK_IN_THE_FUTURE;

      for (let i = -weeksInThePast; i < weeksInTheFuture; i++) {
        weeks.push({
          id: getTime(addDays(start, i * 7)),
          start: addDays(start, i * 7),
          end: addDays(end, i * 7),
        });
        if (i === 0) {
          setCurrentWeek(weeks.at(-1));
        }
      }

      setWeeks(weeks);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWeeks();
  }, [settings, user]);

  return { weeks, currentWeek, loading, error, refetchWeeks: fetchWeeks };
}

export default useFetchWeeks;

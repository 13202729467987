import {
  Box,
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as ReactRouterLink } from "react-router-dom";
import LoginForm from "./login-form";

type LoginModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const LoginModal = ({ isOpen, onClose }: LoginModalProps): ReactElement => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          {/* header */}
          <ModalHeader>{t("login.title")}</ModalHeader>
          <ModalCloseButton />

          {/* body */}
          <ModalBody>
            <LoginForm closeModal={closeModal} setIsLoading={setIsLoading} />
            <Box mt={2}>
              <Link as={ReactRouterLink} to="/forgot-password">
                {t("login.forgot_password")}{" "}
              </Link>
            </Box>
          </ModalBody>

          {/* footer */}
          <ModalFooter>
            <Button
              colorScheme="green"
              width="100%"
              form="login-form"
              type="submit"
              isLoading={isLoading}
            >
              {t("login.login_button")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LoginModal;

import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../api";
import { BookingRequest, BookingType, Court, Hour, UserRole } from "../../api/types";
import useShowToast from "../../hooks/useShowToast";
import useAuthStore from "../../store/auth";

type BookingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  date: Date;
  hour: Hour;
  court: Court;
  onBookingCompleted?: () => void;
};

const BookingModal = ({
  isOpen,
  onClose,
  date,
  hour,
  court,
  onBookingCompleted = () => null,
}: BookingModalProps) => {
  const { t } = useTranslation();
  const [user] = useAuthStore(state => [state.user]);
  const { successToast, errorToast } = useShowToast();

  const [bookingType, setBookingType] = useState<string>(BookingType.SINGLE);
  const [username, setUsername] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const showSuccessToast = () => {
    successToast({
      title: t("booking.modal.success_title"),
      description: t("booking.modal.success_message"),
    });
  };

  const showErrorToast = (errorCode: any) => {
    const description = t(`booking.modal.error_message.${errorCode.detail}`, {
      defaultValue: t("booking.modal.error_message.default"),
    });
    errorToast({
      title: t("booking.modal.error_title"),
      description,
    });
  };

  const requestBooking = async () => {
    setIsLoading(true);

    // Check if username is filled for admin users
    if (user?.role === UserRole.ADMIN && !username) {
      return;
    }

    // Prepare request
    const request: BookingRequest = {
      date,
      hour_id: hour.id,
      court_id: court.id,
      type: bookingType as BookingType,
      username,
    };

    // Request booking
    const booking = await api.requestBooking(request);
    if (api.error) {
      console.log("Error requesting booking", api.error);
      setIsLoading(false);
      onClose();
      showErrorToast(api.error.data);
      return;
    }

    // Close modal
    setIsLoading(false);
    onClose();
    onBookingCompleted();
    showSuccessToast();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="500px">
        {/* Modal title */}
        <ModalHeader>Prenota ora</ModalHeader>

        <Divider />

        {/* Modal Body */}
        <ModalBody>
          {user?.role === UserRole.ADMIN && (
            <>
              <FormControl isRequired isInvalid={!username}>
                <FormLabel htmlFor="username" fontWeight="normal">
                  {t("booking.modal.book_message_admin")}:
                </FormLabel>
                <Input
                  type="text"
                  id="username"
                  placeholder="Username / Nome"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
              </FormControl>
              <Text mt={4} fontWeight="bold">
                {t("booking.modal.details")}:
              </Text>
            </>
          )}

          {user?.role === UserRole.USER && (
            <>
              <Text mb={4}>{t("booking.modal.book_message")}</Text>
            </>
          )}

          {/* Common fields */}

          <Text>
            <Text as="span" fontWeight="bold">
              {t("booking.modal.court")}:
            </Text>{" "}
            <Text as="span">{court.name}</Text>
          </Text>
          <Text>
            <Text as="span" fontWeight="bold">
              {t("booking.modal.date")}:
            </Text>{" "}
            <Text as="span">{format(date, "dd/MM/yyyy")}</Text>
          </Text>
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.hour")}:
            </Text>
            {"   "}
            <Text as="span">{hour.label}</Text>
          </Text>

          {/* Options for booking type */}
          <HStack>
            <Text fontWeight="bold">{t("booking.modal.type")}:</Text>
            <RadioGroup onChange={setBookingType} value={bookingType}>
              <HStack spacing={4}>
                <Radio value={BookingType.SINGLE}>{t("booking.modal.single")}</Radio>
                <Radio value={BookingType.DOUBLE}>{t("booking.modal.double")}</Radio>
                <Radio value={BookingType.SOCIAL_CHAMPIONSHIP}>
                  {t("booking.modal.social_championship")}
                </Radio>
              </HStack>
            </RadioGroup>
          </HStack>
        </ModalBody>

        <Divider />

        {/* Modal Footer with buttons */}
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            {t("booking.modal.cancel")}
          </Button>
          <Button colorScheme="blue" ml={3} onClick={requestBooking} isLoading={isLoading}>
            {t("booking.modal.book")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BookingModal;

import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { Booking, BookingStatus, UserRole } from "../../api/types";
import useShowToast from "../../hooks/useShowToast";
import useAuthStore from "../../store/auth";
import usePaymentStore from "../../store/payment";

enum EditAction {
  CANCEL = "cancel",
}

type BookingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  booking: Booking;
  onBookingEditCompleted?: () => void;
};

const BookingEditModal = ({
  isOpen,
  onClose,
  booking,
  onBookingEditCompleted = () => null,
}: BookingModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user] = useAuthStore(state => [state.user]);
  const [bookingsToPay, setBookingsToPay] = usePaymentStore(state => [
    state.bookingsToPay,
    state.setBookingsToPay,
  ]);
  const { successToast, errorToast } = useShowToast();
  const [isLoading, setIsLoading] = useState(false);

  const showSuccessToast = (action: EditAction) => {
    successToast({
      title: t(`booking.modal_edit.${action}.success_title`),
      description: t(`booking.modal_edit.${action}.success_message`),
    });
  };

  const showErrorToast = (action: EditAction, errorCode: any) => {
    const description = t(`booking.modal_edit.${action}.error_message.${errorCode.detail}`, {
      defaultValue: t(`booking.modal_edit.${action}.error_message.default`),
    });

    errorToast({
      title: t(`booking.modal_edit.${action}.error_title`),
      description,
    });
  };

  const cancelBooking = async () => {
    setIsLoading(true);

    await api.cancelBooking(booking.id);
    if (api.error) {
      console.log("Error canceling booking", api.error);
      setIsLoading(false);
      onClose();
      showErrorToast(EditAction.CANCEL, api.error.data);
      return;
    }

    // Close modal
    setIsLoading(false);
    onClose();
    onBookingEditCompleted();
    showSuccessToast(EditAction.CANCEL);
  };

  const payBooking = async () => {
    bookingsToPay.push(booking);
    setBookingsToPay(bookingsToPay);
    onClose();
  };

  const printReceipt = async () => {
    const receiptId = booking.receipt_id;
    navigate(`/receipt/${receiptId}`);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="500px">
        {/* Modal title */}
        <ModalHeader>{t("booking.modal_edit.edit_booking")}</ModalHeader>

        <Divider />

        {/* Modal Body */}
        <ModalBody>
          <Text mb={4}>{t("booking.modal_edit.edit_message")}</Text>

          {/* Common fields */}
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.name")}:
            </Text>
            {"    "}
            <Text as="span">
              {booking.user_name} {booking.user_surname} ({booking.username})
            </Text>
          </Text>
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.court")}:
            </Text>
            {"  "}
            <Text as="span">{booking.court_label}</Text>
          </Text>
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.date")}:
            </Text>
            {"      "}
            <Text as="span">{format(booking.date, "dd/MM/yyyy")}</Text>
          </Text>
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.hour")}:
            </Text>
            {"        "}
            <Text as="span">{booking.hour_label}</Text>
          </Text>

          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.type")}:
            </Text>
            {"       "}
            <Text as="span">{t(`booking.modal.${booking.type}`)}</Text>
          </Text>
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal_edit.status.title")}:
            </Text>
            {"     "}
            <Text as="span">
              {t(`booking.modal_edit.status.${booking.status}`, {
                defaultValue: t("booking.modal_edit.status.unknown"),
              })}
            </Text>
          </Text>
        </ModalBody>

        <Divider />

        {/* Modal Footer with buttons */}
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            {t("booking.modal_edit.btn_cancel")}
          </Button>

          {/* Cancel */}
          {booking.status !== BookingStatus.PAID && (
            <Button colorScheme="orange" ml={3} onClick={cancelBooking} isLoading={isLoading}>
              {t("booking.modal_edit.cancel.btn_title")}
            </Button>
          )}

          {/* admin only buttons */}
          {user?.role === UserRole.ADMIN && (
            <>
              {/* Pay */}
              {booking.status !== BookingStatus.PAID && (
                <Button
                  colorScheme="green"
                  ml={2}
                  onClick={payBooking}
                  isDisabled={bookingsToPay.includes(booking)}
                >
                  {t("booking.modal_edit.pay.btn_title")}
                </Button>
              )}

              {/* Print again receipt */}
              {booking.status === BookingStatus.PAID && (
                <Button colorScheme="blue" ml={2} onClick={printReceipt}>
                  {t("booking.modal_edit.print_receipt.btn_title")}
                </Button>
              )}
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BookingEditModal;
